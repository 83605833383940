<template>
    <div>
        <!-- TODO: Hedaer image optimization -->
        <div id="news-header" class="entry-short"
            v-bind:style="{ backgroundImage: 'url(' + newsListHeaderImage + ')' }">
            <div class="context">
                <h1>Blog</h1>
            </div>
        </div>

        <div class="breadcrumbs-container">
            <div class="breadcrumbs">
                <router-link to="/">Home</router-link><img class="breadcrumb-arrow" src="~@/assets/arrow_right.svg">
                <router-link to="/blog">Blog</router-link>
            </div>
        </div>
        <div class="content-categories-container">
            <ContentCategories articleEntity="blog" class="content-categories" />
        </div>
        <CardList :list="blogArticleList" contentType="blogArticles" />
        <!--<AlternatingList :list="alternatingList" contentType="newsArticles"/>

        <Pagination
            v-if="total > 1"
            path="/news"
            :total="total"
            :page="page"
            scrollAnchor="news-header"
        />-->
    </div>
</template>

<script>
import { totalBlogArticlesQuery, blogArticlesQuery, globalOptionQuery } from '@/queries'
import { dateFormat } from '@/helper'
import { config } from '@/config'

export default {
    name: 'Blog',

    data() {
        return {
            totalBlogArticles: 0,
            blogArticles: null,
            dateFormat: dateFormat,
            newsListHeader: null,
            limit: 100
        }
    },

    components: {
        //AlternatingList,
        //Pagination
        CardList: () => import('@/components/CardList.vue'),
        ContentCategories: () => import('@/components/PageBuilder/ContentCategories.vue')
    },

    apollo: {
        totalBlogArticles: {
            query: totalBlogArticlesQuery,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.blogArticles.meta.pagination.total
        },

        blogArticles: {
            query: blogArticlesQuery,
            variables() {
                return {
                    limit: this.limit,
                    start: this.start
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        },

        newsListHeader: {
            query: globalOptionQuery,
            variables: {
                optionId: 'news-list-header'
            },
            fetchPolicy: 'cache-first',
            update: data => data.globalOptions.data[0]
        }

    },

    metaInfo() {
        var title = config.globalPageTitle + ' - News'
        return {
            title: title
        }
    },

    computed: {
        page() {
            return this.$route.query.page ? this.$route.query.page : 1
        },

        start() {
            return (this.page - 1) * this.limit
        },

        total() {
            return Math.ceil(this.totalBlogArticles / this.limit)
        },

        newsListHeaderImage() {
            if (this.newsListHeader) {
                return this.getImageUrl(this.localize(this.newsListHeader.attributes.image).data.attributes.url)
            }
        },

        blogArticleList() {
            return this.blogArticles?.data.slice().sort((a, b) => {
                var displayDateA = new Date(a.attributes.dateOverride ?? a.attributes.createdAt);
                var displayDateB = new Date(b.attributes.dateOverride ?? b.attributes.createdAt);

                return displayDateA > displayDateB ? -1 : 1
            }).map((article, index) => {
                // contentCategories can be empty
                const overlayGraphic = article.attributes.contentCategories.data[0]?.attributes.previewOverlayGraphic.data.attributes.url ?? null;
                const overlayStrength = article.attributes.previewOverlayStrength;
                const color = article.attributes.contentCategories.data[0]?.attributes.color ?? '#fff';

                return {
                    index: index,
                    image: article.attributes.titleImage ? this.localize(article.attributes.titleImage).data.attributes : null,
                    overlayGraphic,
                    overlayStrength,
                    color,
                    url: this.url(article),
                    title: this.localize(article.attributes.title),
                    date: this.displayDate(article),
                }
            }) ?? [];
        }
    },

    methods: {
        url(article) {
            return '/blog/' + article.attributes.slug
        },

        displayDate(article) {
            return dateFormat(new Date(article.attributes.dateOverride ?? article.attributes.createdAt));
        }
    }
}
</script>

<style lang="scss">
#news-header {
    background-size: cover;
}

.breadcrumbs-container {
    width: 100%;

    .breadcrumbs {
        background-color: white;
        padding: getSpacing('padding-small-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');
        color: black;
        box-sizing: content-box;
        line-height: 16px;
        font-size: 16px;
        max-width: $container-width;
        margin: 0 auto;

        @include breakpoint('tablet') {
            padding: getSpacing('padding-small-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            //padding-top: 150px;
            padding: getSpacing('padding-small-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
            line-height: unset;
        }

        a {
            color: #273B41;
            font-size: 16px;
        }

        .breadcrumb-arrow {
            width: 7px !important;
            box-sizing: content-box;
            filter: invert(1);
            vertical-align: middle;
            padding: 0px 10px 3px 10px;
        }
    }
}

.content-categories-container {
    width: 100%;

    .content-categories {
        margin: 0 auto;
        max-width: $container-width;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            padding: 0 getSpacing('padding-default-horizontal', 'mobile');
        }
    }
}
</style>
